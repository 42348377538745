import React, { useContext } from 'react'
import { Layout, message } from 'antd'
import { SettingContext } from '../../store/SettingProvider'
import { useLocation } from 'react-router-dom'

const { Footer } = Layout
const date = new Date()

const AppFooter = (props) => {
    const { setDevModeCount } = useContext(SettingContext)
    const location = useLocation()

    const countOnChange = () => {
        if(location.pathname.includes('settings')) {
            setDevModeCount((num) => {
                if (num < 8) {
                    if (num === 7) message.info('已開啟逆送資訊面板')
                    return num + 1
                } else {
                    return num
                }
            })
        }
    }
    return (
        <Footer className="app-footer" {...props}>
            <span onClick={countOnChange}>© {date.getFullYear()} Micro Electricity Co., Ltd. All rights
            reserved.</span>
        </Footer>
    )
}

export default AppFooter
