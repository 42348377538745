//VPP list
export const getTopologyVppGridList = `query emsQuery {  
  vpp_getTopologyVppGridList {
    topologyVPPGridID
    name
    displayName
  }
}`

//VPP info
export const getTopologyVppGridInfo = `query emsQuery($id: ID!){  
  vpp_getTopologyVppGridInfo(id: $id) {
    topologyVPPGridID 
    name
    displayName
    description
    vppType
    vppConfigRef {
      userMode
    }
    vppNodeRef {
      position
      subType
      nodeType
      sourceType
      sinkType
      label
      position
      resourceRef {
          resourceID
          name
          kind
          clientDeviceRef {
          clientDeviceID
          objectRef
        }
      }
    }
  }
}`

//太陽能監控
export const getPowerMonitorPv = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
    vpp_getPowerMonitorPv(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
      objectID
      time
      date
      value
      power
      cumulationValue
    }
}`

//太陽能預測
export const getPowerPredictPv = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerPredictPv(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    power
    cumulationValue
  }
}`

//儲能監控
export const getPowerMonitorEss = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorEss(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    soc
    power
    chargeValue
    dischargeValue
    cumulationChargeValue
    cumulationDischargeValue
    efficiency
    stateOfEnergy
  }
}`

//儲能調度
export const getPowerPredictTargetEss = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerPredictTargetEss(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    power
  }
}`

//負載監控
export const getPowerMonitorLoad = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorLoad(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    cumulationValue
    power
  }
}`

//負載預測
export const getPowerPredictLoad = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerPredictLoad(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    power
  }
}`

//契約容量
export const getContractDemandLimit = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $displayEndTime: String){
  vpp_getContractDemandLimit(id: $id startDatetime: $startDatetime endDatetime: $endDatetime displayEndTime: $displayEndTime) {
    nodeLabel
    time
    demandLimit
    allowedPower
    monitorAllowedPower
    monitorMaxPower
  }
}`

//契約容量_月
export const getContractDemandLimitMonth = `query emsQuery($id: ID! $startDatetime: String! $timescale: String!){
  vpp_getContractDemandLimit(id: $id startDatetime: $startDatetime timescale: $timescale) {
    time
    demandLimit
    maxPower
    monitorAllowedPower
    monitorMaxPower
  }
}`

//灰電
export const getPowerMonitorGray = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerRecordData(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    topologyVPPGridID
    date
    time
    grayEnergy
    grayPower
    cumulationGrayEnergy
    wheelingEnergy
    wheelingPower
    cumulationWheelingEnergy
    gridEnergy
    gridPower
    cumulationGridEnergy
    wheelingPvEnergy
    wheelingPvPower
    cumulationWheelingPvEnergy
    essChargeEnergy
    essChargePower
    cumulationEssChargeEnergy
    essDischargeEnergy
    essDischargePower
    cumulationEssDischargeEnergy
    monitorGridEnergy
    monitorGridPower
    cumulationMonitorGridEnergy
    monitorGridReverseEnergy
    monitorGridReversePower
    cumulationMonitorGridReverseEnergy
  }
}`

//經濟指標
export const getPowerTargetData = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerTargetData(id: $id endDatetime: $endDatetime startDatetime: $startDatetime timezone: $timezone timescale: $timescale) {
    topologyVPPGridID
    year
    month
    date
    time
    re
    gray
    green
    essAchieveRate
    monitorIncome
    monitorCost
    pvFitIncome
    pvFitPower
    essFitIncome
    essFitPower    
    wheelingPower
    selfPower
    uncontrolledBill
    monitorBill
    saveBill
    usedPowerBill
    avgPowerPrice
    contractFine
    saveFine
    greenEffective
    maxGridPower
    maxUncontrolledGridPower
    disposalPower
    carbonReduction
    selfSaveBill
    maxGridPower
    monthFirstWheeling
    yearFirstWheeling
    essBill
    wheelingBill
    wheelingDedBill
    secondWheeling
    secondWheelingBill
    secondWheelingDedBill
    secondWheelingGreenEff
    includeDispatchBill
    excludeDispatchBill
    monthAllWheeling
    yearAllWheeling
    usedPower
    allRe
    allWheelingAchievingRate
    allGreen
    allCarbonReduction
    allGray
    selfRe
    firstWheelingRe
    secondWheelingRe
    monitorContractFine
  }
}`

//充電樁
export const getPowerMonitorEvse = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorEvse( id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale ) {
    objectID
    time
    date
    value
    cumulationValue
    power
  }
}`

//風能實際
export const getPowerMonitorWind = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorPv(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    power
    cumulationValue
  }
}`

//風能預測
export const getPowerPredictWind = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerPredictWind(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    power
    cumulationValue
  }
}`

//查詢裝置總覽
export const getDeviceOverview = `query emsQuery($timezone: String!){
  vpp_getDeviceOverview(timezone: $timezone) {
    deviceTypeCode
    deviceTypeName
    objectID
    objectName
    stateName
    powerLevel
    energy
    energyUsed
    updateTime
    topologyVPPGridID
    topologyVPPGridName
  }
}`

//查詢裝置細節
export const getDeviceInfo = `query emsQuery($objectID: ID! $timezone: String!){
  vpp_getDeviceInfo(objectID: $objectID timezone: $timezone) {
    deviceTypeCode
    deviceTypeName
    objectID
    objectName
    deviceModel
    address
    capacity
    powerLevel
    stateName 
    currentPower 
    storageEnergy
    soc
    soh
    updateTime
  }
}`

// 查詢 Alert Log 清單
export const getAlertLogList = `query emsQuery($startDate: String! $endDate: String! $timezone: String!){
  vpp_getAlertLogList(startDate: $startDate endDate: $endDate timezone: $timezone) {
    alertID
    alertName
    alertLevel
    alertLevelName
    alertOccurredTime
    alertClearedTime
    alertClearedStatus
    alertClearedStatusName
    deviceID
    objectName
    deviceTypeCode
    deviceTypeName
    topologyVPPGridID
    topologyVPPGridName
  }
}`

//查詢告警記錄明細
export const getAlertLogInfo = `query emsQuery($deviceID: ID! $alertID: ID! $alertOccurredTime: String! $timezone: String!){
  vpp_getAlertLogInfo(deviceID: $deviceID alertID: $alertID alertOccurredTime: $alertOccurredTime timezone: $timezone) {
    alertID
    alertName
    alertLevel
    alertLevelName
    alertOccurredTime
    alertClearedTime
    alertClearedStatus
    alertClearedStatusName
    deviceID
    objectName
    deviceTypeCode
    deviceTypeName
    topologyVPPGridID
    topologyVPPGridName
    alertReason
    alertSuggestion
  }
}`

//查詢調度指令及監控資料
export const getDispatchOrderMonitorList = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String!){
  vpp_getDispatchOrderMonitorList(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone) {
    objectRef
    type
    startTime
    endTime
    limitKw
    targetSoc
    targetKwh
    avgActivePower
    sumEnergy
    soc
    mode
  }
}`

//查詢Vpp契約容量尖半六離當月最大功率
export const getVPPContractPower = `query emsQuery($id: ID! $dt: String! $timezone: String!){
  vpp_getVPPContractPower(id: $id dt: $dt timezone: $timezone) {
    topologyVPPGridID
    time
    contractPower
    halfPeakContractPower
    satHalfPeakContractPower
    offPeakContractPower 
  }
}`

//查詢VPP台電合約
export const getTPCContract = `query emsQuery($id: ID){
  vpp_getTPCContract(id: $id) {
    topologyVPPGridID
    topologyVPPGridName
    topologyVPPGridDisplayName
    demandLimit
    halfPeakDemandLimit
    satHalfPeakDemandLimit
    offPeakDemandLimit
    priceContractID
    contractEffectiveDate
    tpcPowerBillID
    timePriceID
    timePriceName
  }
}`

//查詢電價計劃清單
export const getTimePriceList = `query {
  vpp_getTimePriceList {
    timePriceID
    timePriceName
  }
}`

//查詢用電契約更新記錄
export const getPriceContractLog = `query emsQuery($id: ID! $dt: String $timezone: String!){  
  vpp_getPriceContractLog(id: $id dt: $dt timezone: $timezone) {
    priceContractID
    contractEffectiveDate
    timePriceID
    timePriceName
    demandLimit
    halfPeakDemandLimit
    satHalfPeakDemandLimit
    offPeakDemandLimit
    crUser
    crDate
    txUser
    txDate
  }
}`

//查詢時間電價區段
export const getVPPGridTPCTimePriceByDate = `query emsQuery($id: ID! $dt: String!){  
  vpp_getVPPGridTPCTimePriceByDate(id: $id dt: $dt) {
    peakType
    weekType
    startTime
    endTime
  }
}`

//查詢EMS系統參數項目清單
export const getSystemConfigByKey = `query emsQuery($configKey: String!){  
  vpp_getSystemConfigByKey(configKey: $configKey) {
    seq
    key
    value
  }
}`

//查詢查詢異常項目清單
export const getAlertItemList = `query {
  vpp_getAlertItemList {
    seq
    key
    value
  }
}`

// 查詢 Alert Log 清單2
export const getAlertLogList2 = `query emsQuery($alertLogID: String $vppID: String $alertLevel: String $checkType: String $equipmentLevel: String $expired: String $alertID: String $startDatetime: String $endDatetime: String $timezone: String!){
  vpp_getAlertLogList2(alertLogID: $alertLogID vppID: $vppID alertLevel: $alertLevel checkType: $checkType equipmentLevel: $equipmentLevel expired: $expired alertID: $alertID startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone) {
    alertLogID
    alertOwnerID
    alertID
    alertName
    startTimestamp
    endTimestamp
    topologyVPPGridID
    topologyVPPGridName
    expired
    checkType
    checkTypeName
    equipmentLevel
    equipmentLevelName
    alertLevel
    alertLevelName
  }
}`

//查詢異常診斷內容
export const getAlertLogInfo2 = `query emsQuery($alertLogID: String! $timezone: String!){
  vpp_getAlertLogInfo2(alertLogID: $alertLogID timezone: $timezone) {
    alertLogID
    alertOwnerID
    alertID
    alertName
    startTimestamp
    endTimestamp
    topologyVPPGridID
    topologyVPPGridName
    expired
    checkType
    checkTypeName
    equipmentLevel
    equipmentLevelName
    alertLevel
    alertLevelName
    alertReason
    alertSuggestion
    additionInformType
    objectID
    signalOwnerID
    signalName
  }
}`

//查詢拓撲轉供上限
export const getTopologyWheelingLimit = `query emsQuery($id: ID!){
  vpp_getTopologyWheelingLimit(id: $id) {
    topologyVPPGridID
    monthWheelingLimit
    yearWheelingLimit
  }
}`

//查詢綠電轉供一次匹配轉供量
export const getPowerMonitorWheeling = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorWheeling(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    topologyVPPGridID
    time
    value
    power
    cumulationValue
  }
}`

// 查詢異常SOC超限資料
export const getAlertSOCOverLimit = `query emsQuery($objectID: String! $startDateTime: String! $endDateTime: String! $timezone: String!){
  vpp_getAlertSOCOverLimit(objectID: $objectID startDateTime: $startDateTime endDateTime: $endDateTime timezone: $timezone) {
    objectID
    time
    soc
  }
}`

// 查詢異常SOC升降資料
export const getAlertSOCAbnormally = `query emsQuery($signalOwnerID: String! $signalName: String! $startDateTime: String! $endDateTime: String! $timezone: String!){
  vpp_getAlertSOCAbnormally(signalOwnerID: $signalOwnerID signalName: $signalName startDateTime: $startDateTime endDateTime: $endDateTime timezone: $timezone) {
    objectID
    signalOwnerID
    ownerType
    startTimestamp
    endTimestamp
    essFirstSOC
    essLastSOC
    realSocDiff
    expectedSOC
  }
}`

//查詢充電站
export const getPowerMonitorChargeStation = `query emsQuery($id: ID! $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorChargeStation(id: $id startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    time
    date
    value
    cumulationValue
    power
  }
}`

//查詢充電樁
export const getPowerMonitorChargePoint = `query emsQuery($objectID: ID! $deviceID: ID $startDatetime: String! $endDatetime: String! $timezone: String! $timescale: String!){
  vpp_getPowerMonitorChargePoint(objectID: $objectID deviceID: $deviceID startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone timescale: $timescale) {
    objectID
    deviceID
    date
    time
    value
    power
    cumulationValue
    chargePointID
    chargePointName
  }
}`

//查詢充電樁狀態
export const getChargePointStatus = `query emsQuery($objectID: ID! $deviceID: ID $startDatetime: String! $endDatetime: String! $timezone: String!){
  vpp_getChargePointStatus(objectID: $objectID deviceID: $deviceID startDatetime: $startDatetime endDatetime: $endDatetime timezone: $timezone) {
    objectID
    deviceID
    chargePointID
    time
    chargePointStatus
    chargePointStatusName
    chargePointName
  }
}`

//查詢企業綠電彙總
export const getGreenPower = `query emsQuery($startDate: String! $endDate: String! $timescale: String!){
  vpp_getGreenPower(startDate: $startDate endDate: $endDate timescale: $timescale) {
    powerNumberID
    powerNumberName
    month
    year
    loadPower
    greenPower
    garyPower
    greenBill
    monthWheelingLimit
    yearWheelingLimit
    wheelingAchievingRate
    carbonReduction
    re
    greenCertificateAmount
  }
}`

//查詢企業各電號
export const getGreenPowerNumber = `query emsQuery($id: ID $startDate: String! $endDate: String! $timescale: String!){
  vpp_getGreenPowerPowerNumber(id: $id startDate: $startDate endDate: $endDate timescale: $timescale) {
    powerNumberID
    powerNumberName
    month
    year
    loadPower
    greenPower
    garyPower
    greenBill
    monthWheelingLimit
    yearWheelingLimit
    wheelingAchievingRate
    carbonReduction
    re
    greenCertificateAmount
  }
}`